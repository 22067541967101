import React from "react";
import { TableCell } from "@material-ui/core";
import PropTypes from "prop-types";

export const DataCell = ({ width, children, classes, className, ...props }) => {
	return (
		<TableCell style={{ width: width }} className={className ? `${classes.cellStyle} ${className}` : `${classes.cellStyle}`} {...props}>
			{children}
		</TableCell>
	);
};
DataCell.propTypes = {
	width: PropTypes.string,
};

export default DataCell;
