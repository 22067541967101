import uuid from "uuid";
import _ from "lodash";

export const transformToHierarchy = links => {
	const nodesByName = {};

	const assignNode = name => {
		//let exists = _.forEach(nodesByName, (node) => (node.name === name && node.id === id));
		if (!nodesByName[name]) {
			nodesByName[name] = { name };
		}
		return nodesByName[name];
	};

	const assignNodeWithAttributes = (name, attributes) => {
		if (!nodesByName[name]) {
			nodesByName[name] = {
				name,
				attributes,
			};
		}
		return nodesByName[name];
	};

	// Create nodes for each unique source and target.
	links.forEach(link => {
		link.source = assignNode(link.parent);
		link.target = assignNodeWithAttributes(link.child, link.attributes);
		const parent = link.source;
		const child = link.target;

		if (!parent.id) {
			parent.id = uuid.v4();
		}
		child.id = uuid.v4();
		child.parent = parent.name || null;

		parent._collapsed = child._collapsed = true; // eslint-disable-line
		parent._children ? parent._children.push(child) : (parent._children = [child]);
	});

	// Extract & return the root node
	const rootLinks = links.filter(link => !link.source.parent);
	return [rootLinks[0].source];
};

export const transformToHierarchyV2 = links => {
	_.forEach(links, link => (link.id = uuid.v4()));

	if (links.length === 0) return;

	let root = _.filter(links, link => link.idParent === null)[0];

	populateChildren(root, links);

	return [root];
};

const populateChildren = (item, links) => {
	item.children = _.filter(links, link => link.idParent === item.uid);

	_.forEach(item.children, child => {
		populateChildren(child, links);
	});

	item.attributes.taxonomy !== "PEMCATALOG" &&
		item.children.push({
			name: `Add`,
			idObj: item.idObj,
			uid: item.uid,
			attributes: { taxonomy: `ADDNEW-${item.attributes.taxonomy}` },
		});
};
