import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails, getUserAvatar, selectIsUserDetailsOpen, selectOrganizations } from "./selectors";
import WelcomeScreen from "./welcome-modal";
import { uploadUserAvatar, getOrganizations, saveUserDefaultOrganization } from "./actions";

export default ({ props }) => {
	const userDetails = useSelector(getUserDetails);
	const userAvatar = useSelector(getUserAvatar);
	const isUserDetailsOpen = useSelector(selectIsUserDetailsOpen);
	const organizations = useSelector(selectOrganizations);

	const dispatch = useDispatch();


	useEffect(() => {
		if (userDetails?.roles?.length === 0)
			dispatch(getOrganizations())
	}, [userDetails])

	return <WelcomeScreen isOpen={isUserDetailsOpen}
		uploadUserAvatar={uploadUserAvatar}
		userDetails={userDetails}
		userAvatar={userAvatar}
		organizations={organizations}
		saveUserOrganization={(organization) => dispatch(saveUserDefaultOrganization(organization))} />;
};