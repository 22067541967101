import commonMenuItems from "./common-menu-items";

export default currentUser => ([
	{
		text: "myTasks",
		path: "/my-tasks",
		icon: "Icon_MyTasks",
		isImplemented: true,
		accessPermissions: ["MyTasks_Page"],
		isNew: false,
	},
	{
		text: "Dashboard",
		path: "/dashboard",
		icon: "dashboard",
		isImplemented: true,
		accessPermissions: ["Dashboard_Page"],
		isNew: false,
	},
	{
		text: "Projects",
		path: "/customer-projects/overview",
		icon: "projects",
		isImplemented: true,
		accessPermissions: ["Customer_Projects_Page"],
		isNew: false,
	},
	{
		text: "Projects",
		path: "/projects/overview",
		icon: "projects",
		isImplemented: true,
		accessPermissions: ["Projects_Page"],
		isNew: false,
	},
	{
		text: "Jungle Navigator",
		path: "/jungle-navigator",
		icon: "Jungle_Navigator",
		isImplemented: true,
		accessPermissions: ["Jungle_Navigator_Page"],
		isNew: false,
	},
	{
		text: "Lessons Learned",
		path: "/lessons-learned?tab=global",
		icon: "Icon_Lessons",
		isImplemented: true,
		accessPermissions: ["Projects_Page"],
		isNew: false,
	},
	{
		text: "Administration",
		path: "",
		icon: "configure",
		isImplemented: true,
		accessPermissions: [
			"User_Management_Page",
			"GES_Online_Page",
			"Portal_Navigator_Administrator_Page",
			"Carousel_Management_Page",
			"Role_Write",
			"Role_Management_Page",
		],
		isNew: false,
		children: [
			{
				text: "User Management",
				path: "/user-management",
				isImplemented: true,
				accessPermissions: ["User_Management_Page"],
				isNew: false,
			},
			{
				text: "GES Online",
				path: "/ges-online",
				isImplemented: true,
				accessPermissions: ["GES_Online_Page"],
				isNew: false,
			},
			{
				text: "Jungle Navigator",
				path: "/admin-portal-navigator",
				isImplemented: true,
				accessPermissions: ["Portal_Navigator_Administrator_Page"],
				isNew: false,
			},
			{
				text: "Carousel Management",
				path: "/carousel-management",
				isImplemented: true,
				accessPermissions: ["Carousel_Management_Page"],
				isNew: false,
			},
			{
				text: "Role Management",
				path: "/role-management",
				isImplemented: true,
				accessPermissions: ["Role_Write", "Role_Management_Page"],
				isNew: false,
				children: [
					{
						text: "Role Management",
						path: "/role-management",
						isImplemented: true,
						accessPermissions: ["Role_Write", "Role_Management_Page"],
						isNew: false,
					},
				],
			},
			{
				text: "Feedback Management",
				path: `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_FEEDBACK_MANAGER_URL : window.EnvironmentConfig.feedbackManagerUrl}`,
				isImplemented: true,
				accessPermissions: ["Feedback_Management_Page"],
				isNew: false,
			},
		],
	},
	...commonMenuItems(currentUser)
	// {
	// 	text: "Help Desk",
	// 	icon: "helpdesk",
	// 	isImplemented: true,
	// 	isNew: false,
	// 	isFooter: true,
	// 	accessPermissions: ["HelpDesk_Page"],
	// 	children: [
	// 		{
	// 			text: "Service Now",
	// 			path: "https://yokogawa.service-now.com/",
	// 			isImplemented: true,
	// 			isNew: false,
	// 		},
	// 		{
	// 			text: "Community",
	// 			path: "https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMzc4OTk4MzUzOTIifQ",
	// 			isImplemented: true,
	// 			isNew: true,
	// 		},
	// 		{
	// 			text: "Contact Us",
	// 			path: "mailto:support_myproject@ml.jp.yokogawa.com?subject=Helpdesk",
	// 			isImplemented: true,
	// 			isNew: true,
	// 		},
	// 	],
	// },
	// {
	// 	text: "Videos",
	// 	path: "https://ykgwoffice.sharepoint.com/sites/myProjectReleaseNotes/Lists/myProject%20Playlist/AllItems.aspx",
	// 	icon: "stream",
	// 	isImplemented: true,
	// 	accessPermissions: ["Videos_Page"],
	// 	isNew: false,
	// 	isFooter: true,
	// },
	// {
	// 	text: "Release Notes",
	// 	path: currentUser?.authenticationType?.toLowerCase() === "sso_account" ? "/release-notes" : "https://ykgwoffice.sharepoint.com/sites/myProjectReleaseNotes",
	// 	icon: "news",
	// 	isImplemented: true,
	// 	accessPermissions: ["Release_Notes_Page"],
	// 	isNew: false,
	// 	isFooter: true,
	// },

]);