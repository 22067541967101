import React from "react";
import { Button } from "@material-ui/core";

export default ({ name, classes, onClick, children, ...props }) => {
	return (
		<>
			<Button className={classes.button} variant="contained" color="primary" onClick={onClick}>
				{children}
			</Button>
		</>
	);
};
