import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Typography, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import Modal from "../../../components/modal";
import setNewsBulletinStyle from "./styles";
import { setShowCarousel } from "../../../services/api-service/local-storage";
import PrimaryButton from "components/common/primary-button";

const NewsBulletin = ({ items, ...props }) => {
	const classes = setNewsBulletinStyle();

	const [showModal, setShowModal] = useState(true);

	const [open, setOpen] = useState(true);
	const handleClose = () => {
		if (!showModal) {
			setShowCarousel(false);
		}
		setOpen(false);
	};

	const content = (
		<div id="carousel-pop-up-content">
			<Carousel interval={7000}>
				{items?.map(item =>
					item.link !== null ? (
						<a href={item.link} target={item.target}>
							<div key={item.uid}>
								<img className={classes.image} src={item.content} />
							</div>
						</a>
					) : (
						<div key={item.uid}>
							<img className={classes.image} src={item.content} />
						</div>
					)
				)}
			</Carousel>
			<FormControlLabel
				control={<Checkbox id="do-not-show-this-on-next-login-checkbox" onChange={() => setShowModal(!showModal)} />}
				label={<Typography className={classes.txtStyle}>Do not show this popup again until the next update.</Typography>}
			/>
			<Grid container justify="center">
				<PrimaryButton onClick={handleClose}>Close</PrimaryButton>
			</Grid>
		</div>
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			body={content}
			customStyle={{
				width: "auto",
				backgroundColor: "white",
				borderRadius: "15px",
				padding: "10px",
				overflow: "visible",
			}}
		/>
	);
};

export default NewsBulletin;
