import httpBase from "./api-service/base-service";
import * as route from "./api-service/constants";

const UiConfigurationService = {
    getCarouselImages: () => httpBase.get(`${route.UI_CONFIGURATION_URI}/CarouselImages?$select=uid,name,description,target,link,content&$orderby=orderNumber&$filter=isDisabled eq false`),
    getCarouselAdminImages: () => httpBase.get(`${route.UI_CONFIGURATION_URI}/CarouselImages?$select=uid,name,description,link,target,isDisabled,base64Content&$orderby=orderNumber`),
    saveCarousels: images => httpBase.post(`${route.UI_CONFIGURATION_URI}/CarouselImages`, images),
    getServices: () => httpBase.get(`${route.UI_CONFIGURATION_URI}/IntegratedServices`),
    getPermissions: (url, permissionsUrl) => httpBase.get(`${url}${permissionsUrl}`)
};

export default UiConfigurationService;