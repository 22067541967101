export default theme => ({
	content: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(4),
	},
	label: {
		fontSize: "36px",
		color: theme.palette.primary.main,
	},
	title: {
		fontFamily: "NotoSans-SemiBold",
		color: theme.palette.text.primary,
		fontSize: "1.125rem",
		height: "60px",
	},
});
