import React from "react";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";

const BackdropLoader = ({ classes, ...props }) => {
	return (
		<Backdrop className={classes.backdrop} open>
			<Grid item>
				<div className={classes.multiRipple}>
					<div className={classes.donut} />
					<div className={classes.donut} />
				</div>
			</Grid>
		</Backdrop>
	);
};

export default BackdropLoader;
