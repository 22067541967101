import React, { useState } from "react";
import clsx from "clsx";

import { AppBar, Toolbar, Typography, IconButton, Badge, Box, Avatar } from "@material-ui/core";
import Logo from "../../assets/images/MyProject_logo.svg";
import NotificationsIcon from "@material-ui/icons/Notifications";
import UserPopoverDetails from "./../user-popover-details";
import NotificationFlag from "../notification-flag";
import ProjectTitle from "../project-title";
import { propertyOf } from "lodash";
import NotificationServicesManager from "../notification-services-manager";

export default ({ open, onLogoClick, ToggleSidebar, userAvatar, classes, notifications, notificationServices, handleSwitchChange, currentProject, ...props }) => {
	const [anchorElement, setAnchorElement] = useState(null);

	return (
		<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
			<Toolbar>
				<img src={Logo} alt={"Oprex"} onClick={() => onLogoClick()} className={classes.logo} />
				{currentProject && <>|</>}
				<Typography variant="h6" color="inherit" noWrap className={classes.title}>
					{currentProject && `${currentProject.number} - ${currentProject.name}`}
				</Typography>
				{/* <NotificationFlag notifications = {notifications} /> */}
				
				<NotificationServicesManager
					classes={classes}
					notificationServices={notificationServices}
					handleSwitchChange={handleSwitchChange}
			    />
				<Box
					id="avatar-box"
					m={1}
					onClick={event => {
						setAnchorElement(prev => {
							return prev === null ? event.currentTarget : null;
						});
					}}>
					<Avatar id="avatar" alt="Stock avatar" src={userAvatar} />
					<UserPopoverDetails isOpen={Boolean(anchorElement)} anchorElement={anchorElement} {...props} />
				</Box>
			</Toolbar>
		</AppBar>
	);

};
