import React from "react";
import { Typography, Grid } from "@material-ui/core";

export default ({ selectedProject, label, classes, ...props }) => {
	return (
		<Grid item container xs={12} spacing={1} className={classes.content}>
			<Grid item xs={12}>
				<Typography className={classes.title}>
					{selectedProject?.number} - {selectedProject?.name}
				</Typography>
			</Grid>
			{label ? (
				<Grid item xs={12}>
					<Typography className={classes.label}>{label}</Typography>
				</Grid>
			) : null}
		</Grid>
	);
};
