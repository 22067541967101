import hash from "object-hash";

export const saveToken = token => window.localStorage.setItem("myProject", JSON.stringify(token));
export const getToken = () => JSON.parse(window.localStorage.getItem("myProject"));
export const deleteToken = () => window.localStorage.removeItem("myProject");

export const saveEDmsToken = token => setLocalStorageData("edms", token);
export const getEDmsToken = () => getLocalStorageData("edms");
export const deleteEDmsToken = () => window.localStorage.removeItem("edms");

export const getAccessToken = () => {
	let token = window.localStorage.getItem("myProject");
	return token !== null ? JSON.parse(token).accessToken : "";
};

const getLocalStorageData = keyname => JSON.parse(window.localStorage.getItem(keyname));
const setLocalStorageData = (keyname, value) => window.localStorage.setItem(keyname, JSON.stringify(value));

export const setShowCarousel = (show) => {
	let carouselInfo = getLocalStorageData("carouselInfo");
	carouselInfo.show = show;
	setLocalStorageData("carouselInfo", carouselInfo);
}

export const setCarouselInfo = (carouselInfo) => {
	setLocalStorageData("carouselInfo", carouselInfo);
}

export const getCarouselInfo = () => {
	let carouselInfo = getLocalStorageData("carouselInfo");
	return carouselInfo !== null ? carouselInfo : { show: true, hash: null };
};

