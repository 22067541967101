export const NOTIFICATION = "NOTIFICATION";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";

export const UPLOAD_USER_AVATAR = "UPLOAD_USER_AVATAR";
export const UPLOAD_USER_AVATAR_FETCH = "UPLOAD_USER_AVATAR_FETCH";
export const UPLOAD_USER_AVATAR_FETCHED = "UPLOAD_USER_AVATAR_FETCHED";

export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const GET_ORGANIZATIONS_FETCH = "GET_ORGANIZATIONS_FETCH";
export const GET_ORGANIZATIONS_FETCHED = "GET_ORGANIZATIONS_FETCHED";

export const NAVIGATE_HOME = "NAVIGATE_HOME";

export const DISPLAY_USER_INFO = "DISPLAY_USER_INFO";

export const GET_NOTIFICATION_FLAG = "GET_NOTIFICATION_FLAG";
export const GET_NOTIFICATION_FLAG_FETCH = "GET_NOTIFICATION_FLAG_FETCH";
export const GET_NOTIFICATION_FLAG_FETCHED = "GET_NOTIFICATION_FLAG_FETCHED";
