import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import ReactWebChat, { createDirectLine, createStore } from "botframework-webchat";
import { ConnectionStatus } from "botframework-directlinejs";

import "./fabric-icons-inline.css";
import "./style.css";

import useTimer from "./user-timer";
const TIME_INTERVAL = 180000;

const MinimizableWebChat = ({ chatBotDirectlineUrl, accessToken, isExpertUser, directLineSecret, ...props }) => {
	const [directLine, setDirectLine] = useState(createDirectLine({}));
	const [store, setStore] = useState();
	const [cleared, setCleared] = useState(false);

	const initConversation = useCallback(() => {
		setStore(
			createStore({}, ({ dispatch }) => next => action => {
				if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
					if (isExpertUser === null) {
						isExpertUser = false;
						console.error("VALUE CANNOT BE NULL:isExpertUser flag is null, please consider to debug this error.");
					}
					dispatch({
						type: "WEB_CHAT/SEND_EVENT",
						payload: {
							name: "webchat/join",
							value: {
								language: window.navigator.language,
								retrieveHistory: cleared ? false : true,
								isExpert: isExpertUser,
								accessToken: accessToken,
							},
						},
					});
				} else if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
					if (action.payload.activity.from.role === "bot") {
						if (action.payload.activity.type === "message") pushNotification();
						setNewMessage(true);
						let inputHint = Number(action.payload.activity.inputHint);
						if (isNaN(inputHint)) {
						} else if (inputHint > 0) {
							setExpertUserAvailable(true);
						} else {
							setExpertUserAvailable(false);
						}
					}
				} else if (action.type === "WEB_CHAT/SUBMIT_SEND_BOX") {
					// Reset the timer when the user sends an activity
					setTimeRemaining(TIME_INTERVAL);
				}
				return next(action);
			})
		);

		async function fetchJSON(url, options = {}) {
			const res = await fetch(url, {
				...options,
				headers: {
					...options.headers,
					accept: "application/json",
				},
			});

			if (!res.ok) throw new Error(`Failed to fetch JSON due to ${res.status}`);

			return await res.json();
		}

		(async function () {
			const { token } = await fetchJSON(chatBotDirectlineUrl, {
				headers: {
					authorization: `Bearer ${directLineSecret}`,
				},
				method: "POST",
			});
			setDirectLine(createDirectLine({ token }));
		})().catch(error => console.log(error));
	}, [setStore, setDirectLine, cleared, directLineSecret]);

	useEffect(initConversation, [cleared]);

	const checkTabVisibility = () => {
		if (document.hidden) return false;
		else return true;
	};

	const startTimer = () => {
		console.log("Time elapsed");
		store.dispatch({
			type: "WEB_CHAT/SEND_EVENT",
			payload: {
				name: "webchat/idleTimeout",
				value: {
					interval: TIME_INTERVAL,
				},
			},
		});
	};

	const [timeRemaining, setTimeRemaining] = useTimer(startTimer);

	const handleDisconnectClick = () => {
		setCleared(true);
		setTimeRemaining(0);
		initConversation();
	};

	const handleSendEmailClick = () => {
		store.dispatch({
			type: "WEB_CHAT/SEND_EVENT",
			payload: {
				name: "webchat/sendEmail",
			},
		});
	};

	const handleContactAgentClick = () => {
		store.dispatch({
			type: "WEB_CHAT/SEND_EVENT",
			payload: {
				name: "webchat/contactExpert",
			},
		});
	};

	const handStatusChangeClick = () => {
		store.dispatch({
			type: "WEB_CHAT/SEND_EVENT",
			payload: {
				name: "webchat/statusChange",
			},
		});
	};

	const pushNotification = () => {
		if (checkTabVisibility()) return;
		let topic = "myProject Chatbot";
		let body = "New Message Arrived";
		let notificationIcon = window.location.origin + "/MyProject_Favicon.ico";
		let notification = new Notification(topic, { icon: notificationIcon, body: body });
		setTimeout(() => {
			notification.close.bind(notification);
		}, 1000);
	};

	const [loaded, setLoaded] = useState(false);
	const [minimized, setMinimized] = useState(true);
	const [newMessage, setNewMessage] = useState(false);
	const [expertUserAvailable, setExpertUserAvailable] = useState(false);

	const handleMaximizeButtonClick = useCallback(async () => {
		setLoaded(true);
		setMinimized(false);
		setNewMessage(false);
	}, [setMinimized, setNewMessage]);

	const handleMinimizeButtonClick = useCallback(() => {
		setMinimized(true);
		setNewMessage(false);
	}, [setMinimized, setNewMessage]);

	return (
		<div className="minimizable-web-chat">
			{minimized && (
				<button className="maximize tooltip" onClick={handleMaximizeButtonClick}>
					<span className="tooltiptext">Contact us</span>
					<span className={directLine ? "ms-Icon ms-Icon--MessageFill" : "ms-Icon ms-Icon--Message"} />
					{newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
				</button>
			)}
			{loaded && (
				<div className={classNames("chat-box right", minimized ? "hide" : "")}>
					<header>
						<div className="filler">
							<button className="logosymbol">
								<span className="icon" />
							</button>
						</div>
						<div className="headeractions">
							{!isExpertUser && expertUserAvailable && (
								<button className="switch tooltip" onClick={handleContactAgentClick}>
									<span className="icon-help" />
									<span className="tooltiptext">Expert User is now online</span>
								</button>
							)}
							{!isExpertUser && (
								<button className="switch tooltip" onClick={handleSendEmailClick}>
									<span className="icon-mail" />
									<span className="tooltiptext">Send an email to Expert User</span>
								</button>
							)}
							{isExpertUser && (
								<button className="switch tooltip" onClick={handStatusChangeClick}>
									<span className="icon-expertHelp" />
									<span className="tooltiptext">Change status to online/offline</span>
								</button>
							)}
							<button className="switch tooltip" onClick={handleDisconnectClick}>
								<span className="icon-chat" />
								<span className="tooltiptext">Clear and Start New Conversation</span>
							</button>
							{/* <button className="switch" onClick={handleSettingClick}>
                                <span className="icon-settings" />
                            </button>
                            <button className="switch" onClick={handleCloseClick}>
                                <span className="icon-close" />
                            </button> */}
							<button className="minimize" onClick={handleMinimizeButtonClick}>
								<span className="icon-minimize" />
							</button>
						</div>
					</header>
					<ReactWebChat className="chat" directLine={directLine} store={store} />
				</div>
			)}
		</div>
	);
};

export default MinimizableWebChat;
