export default theme => ({
	flagButton: {
		marginRight: 10,
	},
	notificationPopOver: {
		"& .MuiPopover-paper": {
			maxWidth: 350,
			marginTop: 10,
		},
	},
	closeBtn: {
		left: 320,
	},
	unreadNotificationBody: {
		fontFamily: "NotoSans-SemiBold",
		fontSize: 14,
	},
	readNotificationBody: {
		fonFamily: "NotoSans-Regular",
		fontSize: 14,
	},
	notificationItemsWrapper: {
		maxHeight: "60vh",
		overflow: "auto",
	},
	notificationTitle: {
		paddingRight: 16,
		paddingLeft: 16,
		fontSize: 18,
		fontFamily: "NotoSans-SemiBold",
	},
	notificationTitleColor: {
		color: theme.palette.action.active,
	},
	hoveredItem: {
		"&:hover": {
			"& $notificationTitleColor": {
				color: "white",
			},
			color: "white",
		},
	},
	limitCharactersTitle: {
		maxWidth: "50ch",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
	},
});
