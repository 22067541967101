import React from "react";

export default ({ id, type, size, additionalClasses, customStyle, clickAction, text, ...props }) => {
	const { classes } = props;

	return (
		<span
			className={`${classes[type]} ${additionalClasses ? additionalClasses.map(c => ` ${c}`) : ""}`}
			id={id}
			style={{ ...customStyle, fontSize: size }}
			onClick={clickAction}>
			{text}
		</span>
	);
};
