import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
export default theme => ({});
export const MenuStyled = withStyles(theme => ({
	paper: {
		border: " 1px solid",
		borderColor: theme.palette.secondary.dark,
		overflowY: "unset",
		overflowX: "unset",
		borderRadius: 5,
		"&:before": {
			top: "13px",
			content: '""',
			display: "block",
			width: "0",
			height: "0",
			position: "absolute",
			borderLeft: "15px solid transparent",
			borderRight: "14px solid",
			borderRightColor: theme.palette.secondary.dark,
			borderTop: "15px solid transparent",
			borderBottom: "15px solid transparent",
			left: "-30px",
		},
		"&:after": {
			top: "14px",
			content: '""',
			display: "block",
			width: "0",
			height: "0",
			position: "absolute",
			borderLeft: "14px solid transparent",
			borderRight: "14px solid white",
			borderTop: "14px solid transparent",
			borderBottom: "14px solid transparent",
			left: "-28px",
		},
	},
}))(Menu);
