import { CANCEL } from "redux-saga";
import { cancelTokenSource, getInstance } from "./axios-instance";
import sls from "single-line-string";
import { push } from "connected-react-router";
import { getAccessToken, saveToken, getToken, deleteToken } from "./local-storage";
import { store } from "../../store";
import { ACCOUNT_URI } from "./constants";

let isTokenRefreshing = false;
let subscribers = [];
const onAccessTokenFetched = accessToken => subscribers.map(s => s(accessToken));

export const generateNewAccessToken = () => getNewToken().then(response => saveToken(response.data))

const getNewToken = () => {
	let token = getToken()
	return getInstance().post(`${ACCOUNT_URI}/RefreshToken`, {
		RefreshToken: token.refreshToken,
		ExpiredToken: token.accessToken,
	});
};

const http = interceptors => {
	let localAxiosInstance = getInstance(window.location.pathname);

	for (let i = 0; i < interceptors.request?.length; i++) {
		localAxiosInstance.interceptors.request.use(interceptors.request[i]);
	}

	localAxiosInstance.interceptors.request.use(request => {
		if (request.method == "get") request.url = sls`${request.url}`;

		request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
		return request;
	});

	localAxiosInstance.interceptors.response.use(
		response => response,
		error => {
			if (error?.response?.status === 401) {
				const {
					config,
					response: { status, headers },
				} = error;

				const originalRequest = config;
				const tokenExpired = headers["token-expired"];

				if (tokenExpired) {
					if (!isTokenRefreshing) {
						isTokenRefreshing = true;
						getNewToken()
							.then(response => {
								const { data } = response;
								isTokenRefreshing = false;
								saveToken(data);
								onAccessTokenFetched(getAccessToken());
								subscribers = [];
							})
							.catch(error => {
								isTokenRefreshing = false;
								deleteToken();
								store.dispatch(push("/login"));
								Promise.resolve();
							});
					}

					const retryOriginalRequest = new Promise(resolve => {
						subscribers.push(accessToken => {
							originalRequest._retry = true;
							originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
							resolve(localAxiosInstance(originalRequest));
						});
					});
					return retryOriginalRequest;
				} else {
					if (!localAxiosInstance.isCancel(error)) {
						deleteToken();
						store.dispatch(push("/login"));
					}
				}
			} else return Promise.reject(error);
		}
	);

	for (let i = 0; i < interceptors.response?.length; i++) {
		localAxiosInstance.interceptors.response.use(interceptors.response[i]);
	}

	return localAxiosInstance;
};

export default {
	get: (url, config = {}) => {
		const source = cancelTokenSource();
		const request = http(interceptors).get(url, { ...config, cancelToken: source.token });
		request[CANCEL] = () => source.cancel();
		return request;
	},
	post: (url, object, config = {}) => {
		const source = cancelTokenSource();
		const request = http(interceptors).post(url, object, {
			cancelToken: source.token,
			signal: null,
			...config,
		});
		request[CANCEL] = () => source.cancel();
		return request;
	},
	put: (url, object) => {
		const source = cancelTokenSource();
		const request = http(interceptors).put(url, object, { cancelToken: source.token, signal: null });
		request[CANCEL] = () => source.cancel();
		return request;
	},
	patch: (url, object) => {
		const source = cancelTokenSource();
		const request = http(interceptors).patch(url, object, { cancelToken: source.token, signal: null });
		request[CANCEL] = () => source.cancel();
		return request;
	},
	delete: url => {
		const source = cancelTokenSource();
		const request = http(interceptors).delete(url, { cancelToken: source.token, signal: null });
		request[CANCEL] = () => source.cancel();
		return request;
	},
};

export const interceptors = {
	request: [],
	response: [],
};
