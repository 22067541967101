export default theme => ({
	root: {
		lineHeight: 1,

		"& .MuiListItemText-primary": {
			color: theme.palette.text.primary,
			fontSize: 18,
		},
		"& .MuiListItemIcon-root": {
			color: "#004488",
		},
		"&:hover": {
			"& .MuiListItemText-primary": {
				fontFamily: "NotoSans-CondensedSemiBold",
				color: theme.palette.primary.dark,
			},
			background: "none",
			color: theme.palette.text.primary,
			fontFamily: "NotoSans-SemiBold",
		},
		"&$selected": {
			"& .MuiListItemText-primary": {
				fontFamily: "NotoSans-CondensedSemiBold",
				color: theme.palette.primary.dark,
			},
			background: "none",
			"&:hover": {
				background: "none",
			},
		},
	},
});
