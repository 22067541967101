export const USER_PROFILE_SERVICE_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_UPS_URL : window.EnvironmentConfig.upsUrl}`;
export const ACCOUNT_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_UPS_URL : window.EnvironmentConfig.upsUrl}/accounts`;
export const CATALOG_ADMINISTRATION_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_CAS_URL : window.EnvironmentConfig.casUrl}`;
export const PROJECTS = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_PPS_URL : window.EnvironmentConfig.ppsUrl}`;
export const PORTAL_NAVIGATOR_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_PNV_URL : window.EnvironmentConfig.pnvsUrl}`;
export const E_LIBRARY_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_ELB_URL : window.EnvironmentConfig.elsUrl}`;
export const LESSONS_LEARNED_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_LEL_URL : window.EnvironmentConfig.llsUrl}`;
export const UPS_BLOB_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_UPS_BLOBS_URL : window.EnvironmentConfig.upsBlobUrl;
export const LLS_BLOB_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_LLS_BLOBS_URL : window.EnvironmentConfig.llsBlobUrl;
export const CHAT_BOT_DIRECTLINE_URI =
	process.env.NODE_ENV === "development" ? process.env.REACT_APP_CHATBOT_DIRECTLINE_URL : window.EnvironmentConfig.chatBotDirectLineUrl;
export const CHAT_BOT_DIRECTLINE_SECRET =
	process.env.NODE_ENV === "development" ? process.env.REACT_APP_CHATBOT_DIRECTLINE_SECRET : window.EnvironmentConfig.chatBotDirectLineSecret;
export const CLOUD_DESK_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_CLD_URL : window.EnvironmentConfig.cldUrl;
export const UI_CONFIGURATION_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_UCS_URL : window.EnvironmentConfig.ucsUrl;
export const DOCUMENT_MANAGEMENT_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DOC_MANAGEMENT_URL : window.EnvironmentConfig.docManagementUrl;
export const ELIBRARY_MLS_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_ELB_MLS_URL : window.EnvironmentConfig.elbMlsUrl;
export const PUNCH_LIST_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_PCLT_URL : window.EnvironmentConfig.pcltUrl;
export const TECHNICAL_QUERY_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_TQS_URL : window.EnvironmentConfig.technicalQueryUrl;
export const PROJECT_WORKLISTS_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_PWS_URL : window.EnvironmentConfig.pwsUrl}`;
export const REFERENCE_URI = process.env.NODE_ENV === "development" ? process.env.REACT_APP_RS_URL : window.EnvironmentConfig.referenceUrl;

export const LOGIN_REDIRECT_URI = `${process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOGIN_REDIRECT_URL : window.EnvironmentConfig.loginRedirectUrl}`;

export const DEFAULT_PROJECTS_ORDER_EXPRESSION = "creationDate desc, status/orderNumber ";
export const PROJECT_DTO = {
	id: "id",
	name: "name",
	organization: "organization",
	number: "number",
	projectManager: "projectManager",
	projectManagerName: "projectManager/name",
	status: "status",
	customer: "customer",
	creationDate: "creationDate",
	uid: "uid",
	hasAccess: "hasAccess",
	organizationUid: "organization/uid",
	isTeamMember: "isTeamMember",
	IdParent: "IdParent",
	hasChildren: "hasChildren",
	idParent: "idParent",
};
