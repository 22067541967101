import React from "react";
import { Modal, Fade, Backdrop, Box } from "@material-ui/core";

export default ({ title, body, open, onClose, classes, preloader, customStyle, ...props }) => {
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}>
			<Fade in={open}>
				<div className={classes.paper} style={customStyle}>
					<Box className={classes.modalTitle} component="h2">
						{title}
					</Box>
					{body}
				</div>
			</Fade>
		</Modal>
	);
};
