import React from "react";
import MinimizableWebChat from "./web-chat";
import { withStyles } from "@material-ui/core/styles";
import { CHAT_BOT_DIRECTLINE_URI, CHAT_BOT_DIRECTLINE_SECRET } from "../../services/api-service/constants";
import { useSelector } from "react-redux";
import * as selectors from "./selectors";

const style = theme => ({
	webChatBox: {
		"& ::-webkit-scrollbar": {
			width: "5px",
		},
		"& ::-webkit-scrollbar-track, ::-webkit-scrollbar-track:hover, ::-webkit-scrollbar-track:active": {
			background: "#ffffff",
		},
		"& ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active": {
			border: "1.3px solid #ffffff",
			background: "#3399ff",
		},
	},
});

export default withStyles(style)(({ classes, ...props }) => {
	const currentUser = useSelector(selectors.getUserDetails);
	const accessToken = useSelector(selectors.selectAccessToken);

	return (
		<div className={classes.webChatBox}>
			<MinimizableWebChat
				chatBotDirectlineUrl={CHAT_BOT_DIRECTLINE_URI}
				accessToken={accessToken}
				isExpertUser={currentUser.isExpertUser}
				directLineSecret={CHAT_BOT_DIRECTLINE_SECRET}
			/>
		</div>
	);
});
