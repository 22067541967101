import * as actionType from "./action-types";
import * as actions from "./actions";

const initialState = {
	notifications: [],
	counter: 0,
	sidebarIsOpen: true,
	isLoggedIn: false,
	currentUserDetails: {
		firstLogin: false,
		isExpertUser: false,
	},
	icon: null,
	formErrors: [],
	carousel: [],
	isUserDetailsOpen: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case actionType.NOTIFICATION:
			let { counter } = { ...state };
			counter++;
			return { ...state, notifications: action.notifications, counter };

		case actionType.SIDEBAR_TOGGLE:
			let { sidebarIsOpen } = { ...state };
			return { ...state, sidebarIsOpen: !sidebarIsOpen };

		case actionType.DISPLAY_USER_INFO:
			return { ...state, isUserDetailsOpen: action.payload };

		case actionType.UPLOAD_USER_AVATAR_FETCH:
			return state;

		case actionType.UPLOAD_USER_AVATAR_FETCHED:
			return { ...state, icon: action.avatar };

		case actionType.GET_ORGANIZATIONS_FETCH:
			return { ...state };

		case actionType.GET_ORGANIZATIONS_FETCHED:
			return { ...state, organizations: action.organizations };

		case actionType.GET_NOTIFICATION_FLAG_FETCH:
			return { ...state };

		case actionType.GET_NOTIFICATION_FLAG_FETCHED:
			return { ...state, flagNotifications: action.flagNotifications };

		case actions.receiveNotifications.toString():
			return { ...state, flagNotifications: [{ uid: "a123123", text: action.payload, title: action.payload }] };

		case actions.setFormErrors.toString():
			return { ...state, formErrors: action.payload };

		case actions.getCarouselContentFetch.toString():
			return state;

		case actions.getCarouselContentFetched.toString():
			return { ...state, carousel: action.payload };

		case actions.getCurrentUserDetailsFetch.toString():
			return state;

		case actions.getCurrentUserDetailsFetched.toString():
			return {
				...state,
				currentUserDetails: action.payload,
				icon: action.payload.icon,
				isUserDetailsOpen: action.payload.firstLogin
			};

		case actions.addUserNotificationsOptions.toString():
			return { ...state, userNotifications: action.payload };

		case actions.updateNotificationServicesFetch.toString():
			return state;

		case actions.updateNotificationServicesFetched.toString():
			return {
				...state,
				userNotifications: {...action.payload.notifications}
			};

		default:
			return state;
	}
}
