import { createSelector } from "reselect";
import { getToken, getCarouselInfo } from "../../services/api-service/local-storage";

const rootReducerSelector = state => state.rootReducer;
const loginSelector = () => getToken();
const selectedOrganizations = state => state.rootReducer.organizations;

export const selectNotifications = createSelector([rootReducerSelector], rootReducer => rootReducer.notifications);
export const selectCounter = createSelector([rootReducerSelector], rootReducer => rootReducer.counter);

export const selectSideBar = createSelector([rootReducerSelector], rootReducer => rootReducer.sidebarIsOpen);
export const selectIsLoggedIn = createSelector([loginSelector], token => (token !== null ? true : false));
export const selectAccessToken = createSelector([loginSelector], token => token.accessToken);

export const getUserDetails = createSelector([rootReducerSelector], rootReducer => rootReducer.currentUserDetails);
export const getUserAvatar = createSelector([rootReducerSelector], rootReducer => rootReducer.icon);

export const selectOrganizations = createSelector([selectedOrganizations], selectedOrganizations => selectedOrganizations);

const selectedLocation = state => state.router.location;
export const selectLocation = createSelector([selectedLocation], location => location);

const selectedFlagNotifications = state => state.rootReducer.flagNotifications;
export const selectFlagNotifications = createSelector([selectedFlagNotifications], flagNotifications => flagNotifications);

const selectedProject = state => state.currentProject?.project;
export const selectProject = createSelector([selectedProject], selectedProject => selectedProject);

const selectedFormErrors = state => state.rootReducer.formErrors;
export const selectFormErrors = createSelector([selectedFormErrors], formErrors => formErrors);

export const selectCarouselContent = createSelector([rootReducerSelector], rootReducer => rootReducer.carousel);
export const selectIsUserDetailsOpen = createSelector([rootReducerSelector], rootReducer => rootReducer.isUserDetailsOpen || rootReducer.currentUserDetails?.roles?.length === 0);

const showCarouselSelector = () => getCarouselInfo();
export const selectShowCarousel = createSelector([showCarouselSelector, rootReducerSelector], (carousel, root) => root.carousel.length && carousel.show);

const concertServiceToLabel = str => {
	str = str.replace(/Service$/, '');
	str = str.replace(/Teams$/, '');
	str = str.replace(/Email$/, '');
	str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
	str = str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
	return str;
}

const selectedNotificationServices = state => state.rootReducer.userNotifications;
export const selectNotificationServices = createSelector(
	[selectedNotificationServices], 
	userNotifications => {
		if(!userNotifications)
			return[];
	
	return Object.keys(userNotifications).map(service => ({ 
		label: concertServiceToLabel(service), 
		name: service, 
		isActive: userNotifications[service], 
		type: service.includes('Teams') ? 'Teams' : 'Email'})
	)});

