import { store } from "../../store";
import { error_notify, success_notify } from "../../containers/layout/actions";
import { setFormErrors } from "containers/layout/actions";
import { CANCELED_REQUEST_MESSAGE } from "utils/utils";

export const handleError = async error => {
	if (error.message === CANCELED_REQUEST_MESSAGE) return Promise.reject(CANCELED_REQUEST_MESSAGE);

	const response = error?.response;
	if (response.status === 401) {
		return Promise.reject(error);
	} else if (response.status === 403) {
		store.dispatch(error_notify(["Forbidden! You don't have permission to access this resource."]));
		return Promise.reject(error.response);
	} else if (response.status === 400) {
		store.dispatch(error_notify([...response.data.map(x => x.ErrorMessage)]));
		store.dispatch(setFormErrors([...response.data.map(x => ({ property: x.PropertyName, message: x.ErrorMessage }))]));
		return Promise.reject(error.response);
	} else if (response.status === 404) {
		return Promise.reject(error.response);
	} else if (response.status === 406) {
		return Promise.reject(error);
	} else if (response.status === 511) {
		return Promise.reject(error);
	} else {
		store.dispatch(error_notify(["Oops! Something went wrong, try again later..."]));
		return Promise.reject(error.response);
	}
};

export const handleSuccess = async message => {
	store.dispatch(success_notify([message]));
};
