const drawerWidth = 250;

const setDrawerStyle = theme => ({
	root: {
		display: "flex",
	},

	menuLi: {
		flex: 1,
		"&:focus": {
			outline: "none",
		},
	},
	toolbar: {
		backgroundColor: "#7594a9",
		paddingRight: 24, // keep right padding when drawer closed
	},

	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},

	sidebarToggleIcon: {
		transition: "all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000)",
	},

	menuTitle: {
		color: theme.palette.primary.main,
		textAlign: "center",
		fontSize: "1.1em",
	},

	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		//paddingTop: 64,
		backgroundColor: theme.palette.secondary.main,
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		"& *": {
			color: theme.palette.primary.dark,
		},
		"& .MuiTypography-button": {
			fontFamily: "NotoSans-CondensedBold",
			textTransform: "unset",
			fontSize: "1.1em",
		},
		"& .MuiListItem-button": {
			"&:hover": {
				backgroundColor: theme.palette.secondary.dark,
				"& *": {
					color: theme.palette.yokogawaColors.white,
					borderColor: theme.palette.yokogawaColors.white,
				},
			},
		},
	},

	drawerPaperClose: {
		overflowX: "hidden",
		width: 64,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
		"& $toolbarIcon": {
			justifyContent: "center",
		},
		"& ul li": {
			"& > svg": {
				position: "absolute",
				right: 0,
			},
		},
	},
	"@media (min-width: 600px)": {
		drawerPaperClose: {
			width: 64,
		},
	},
	soonBoxItem: {
		fontSize: 7,
		//position: "absolute",
		marginLeft: 7,
		float: "right",
		marginTop: 4,
		padding: "4px 8px",
		fontWeight: 700,
		border: `1px solid ${theme.palette.primary.dark}`,
		borderRadius: 4,
	},
	soonBoxChildItem: {
		fontSize: 7,
		color: theme.palette.primary.dark,
		marginLeft: 7,
		padding: "4px 8px",
		fontWeight: 700,
		border: "1px solid #335988",
		borderRadius: 4,
	},
	menuIcon: {
		minWidth: 50,
	},

	customScrollBar: {
		"& > div:first-child": {
			display: "flex",
			flexDirection: "column",
		},
		"& > div:nth-of-type(2)": {
			// Selector for vertical scrollbar
		},
		"& > div:nth-of-type(3)": {
			// Selector for horizontal scrollbar
		},
	},
});

export default setDrawerStyle;
