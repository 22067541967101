import uatImg from "./assets/images/uat.jpg";
import devImg from "./assets/images/dev.jpg";
import stImg from "./assets/images/st.jpg";

export const environmentProvider = () => {
	const env = process.env.NODE_ENV === "development" ? process.env.REACT_APP_ENVIRONMENT : window.EnvironmentConfig.env;

	switch (env) {
		case "UAT":
			return {
				show: true,
				image: uatImg,
			};
		case "STAGING":
			return {
				show: true,
				image: stImg,
			};

		case "DEVELOPMENT":
			return {
				show: true,
				image: devImg,
			};
		default:
			return {
				show: false,
				image: devImg,
			};
	}
};
