import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import * as selectors from "./selectors";
import { LeftMenuContext } from "containers/left-menu/context";

import LeftMenu from "components/left-menu";

const LeftMenuContainer = props => {
	const [context, setContext] = useContext(LeftMenuContext);

	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(true);
	const [currentMenuItem, setCurrentMenuItem] = useState(null);
	let userDetails = useSelector(selectors.selectUserDetails);

	const navigateTo = path => {
		let absolutePath = new RegExp('^(?:[a-z+]+:)?//', 'i').test(path) || path.startsWith("mailto:");
		absolutePath ? window.open(path, "_.blank") : dispatch(push(path))
	}

	const checkAccessPermissions = (menuItem, userDetails) => {
		return !menuItem.hasOwnProperty("accessPermissions") || userDetails?.permissions?.some(up => menuItem.accessPermissions?.includes(up));
	}

	return (
		<LeftMenu
			userDetails={userDetails}
			open={isOpen}
			menuItems={context
				?.filter(mi => (checkAccessPermissions(mi, userDetails)) && !mi.hasOwnProperty("isFooter"))
				?.map(mi => ({
					...mi,
					children: mi.children?.filter(
						cmi => checkAccessPermissions(cmi, userDetails)
					),
				}))}
			ToggleSidebar={() => setIsOpen(!isOpen)}
			navigateTo={path => navigateTo(path)}
			currentMenuItem={currentMenuItem}
			setCurrentMenuItem={setCurrentMenuItem}
			footerMenuItems={context
				?.filter(mi => checkAccessPermissions(mi, userDetails) && mi.hasOwnProperty("isFooter"))
				?.map(mi => ({
					...mi,
					children: mi.children?.filter (
						cmi=> checkAccessPermissions(cmi, userDetails)
					)
				}))}
			{...props}
		/>
	);
};

export default LeftMenuContainer;
