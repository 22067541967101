import { Divider, Grid, IconButton, MenuItem, Popover, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ShowMoreText from "react-show-more-text";

export default ({
	notifications,
	open,
	notificationPopUp,
	setNotificationPopUp,
	count,
	setViewed,
	clickNotificationState,
	selectedItems,
	setSelectedItemsState,
	expandRead,
	onClickRead,
	...props
}) => {
	const { classes } = props;

	const closeNotificationPopUp = () => {
		setNotificationPopUp(null);
	};

	return (
		<>
			{notifications ? (
				<div className={classes.content}>
					<Popover
						open={open}
						anchorEl={notificationPopUp}
						onClose={closeNotificationPopUp}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						className={classes.notificationPopOver}>
						<Grid container>
							<Grid item xs={12}>
								<IconButton onClick={closeNotificationPopUp} color="inherit" className={classes.closeBtn}>
									<CloseIcon fontSize="small" />
								</IconButton>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography
											variant="h6"
											align="justify"
											className={`${classes.unreadNotification} ${classes.notificationTitle} ${classes.notificationTitleColor}`}>
											Notifications
										</Typography>
									</Grid>
								</Grid>
								<Divider />
								<div className={classes.notificationItemsWrapper}>
									{notifications?.map(item => (
										<>
											<MenuItem
												key={item.uid}
												style={{ whiteSpace: "normal" }}
												onClick={() => {
													setViewed(item.uid);
													clickNotificationState(item.uid);
												}}>
												<Grid container spacing="1" alignItems="center" className={classes.hoveredItem}>
													<Grid
														item
														xs={12}
														className={`${classes.unreadNotificationBody} ${classes.limitCharactersTitle} ${
															selectedItems[item.uid] ? "" : classes.notificationTitleColor
														} `}>
														{item.title}
													</Grid>
													<Grid
														item
														xs={12}
														className={selectedItems[item.uid] ? classes.readNotificationBody : classes.unreadNotificationBody}>
														<ShowMoreText
															more={<span className={classes.notificationTitleColor}>Read More</span>}
															less={<span className={classes.notificationTitleColor}>Read less</span>}
															onClick={() => {
																onClickRead(item.uid);
															}}
															expanded={expandRead}>
															{item.text}
														</ShowMoreText>
													</Grid>
												</Grid>
											</MenuItem>
											<Divider />
										</>
									))}
								</div>
							</Grid>
						</Grid>
					</Popover>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
