import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as selector from "./selectors";
import * as action from "../layout/actions";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";

const AuthorizedRoute = ({ accessPermissions, ...props }) => {
	const dispatch = useDispatch();

	const currentUser = useSelector(selector.selectCurrentUser);
	const isAuthSet = accessPermissions;
	const isAuthorized = isAuthSet && currentUser.permissions?.some(up => accessPermissions.includes(up));

	useEffect(() => {
		if (!isAuthorized && isAuthSet) {
			props.history.goBack();
			dispatch(action.error_notify(["Not enough user rights for this action."]));
		}
	}, []);

	return <>{(currentUser.permissions || !isAuthSet) && <Route exact={props.exact} path={props.path} component={props.component} />}</>;
};

export default withRouter(AuthorizedRoute);
