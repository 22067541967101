export default theme => ({
	"@keyframes ripple": {
		from: { transform: "scale(0)", opacity: 1 },
		to: { transform: "scale(1)", opacity: 0 },
	},

	multiRipple: {
		width: "2rem",
		height: "2rem",
		margin: "auto",
	},
	donut: {
		position: "absolute",
		width: "2rem",
		height: "2rem",
		borderRadius: "50%",
		border: "0.3rem solid",
		borderColor: theme.palette.info.main,
		animation: "1.5s $ripple infinite",
		"&:nth-child(2)": {
			animationDelay: "0.5s",
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.info.main,
		backgroundColor: "rgba(255,255,255,0.06)",
	},
});
