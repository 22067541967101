import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, TextField, MenuItem } from "@material-ui/core";
import Avatar from "react-avatar-edit";

import Modal from "components/modal";
import DataBody from "components/common/data-body";
import HeaderBody from "components/common/header-body";
import DataTable from "components/common/data-table";
import HeaderCell from "components/common/header-cell";
import HeaderRow from "components/common/header-row";
import DataRow from "components/common/data-row";
import DataCell from "components/common/data-cell";
import DataTableContainer from "components/common/data-table-container";

export default ({ uploadUserAvatar, userDetails, isOpen, userAvatar, organizations, saveUserOrganization, ...props }) => {
	const [modalState, setModalState] = useState({ isOpen });
	const [userOrganization, setUserOrganization] = useState({ value: null, showError: false });
	const dispatch = useDispatch();
	const srcState = null;
	const [editMode, setEditMode] = useState(false);
	const { classes } = props;
	const [showRoles, setShowRoles] = useState(false)

	const onCloseEvent = () => {
		if (userDetails?.roles?.length > 0) {
			setModalState({ isOpen: false });
		}
		else {
			if (userOrganization.value === null)
				setUserOrganization({ ...userOrganization, showError: true })
			else
				saveUserOrganization(userOrganization.value)
		}
	};

	useEffect(() => {
		setModalState({ isOpen: isOpen });
	}, [isOpen]);

	const handleChange = (e) => {
		setUserOrganization({ value: e.target.value, showError: false })
	}

	const body = (
		<div>
			<Grid container justify="center" spacing={3}>
				<Grid item className={classes.avatar}>
					{userDetails.icon === null || (userDetails.icon !== null && editMode) ? (
						<Avatar
							width={150}
							height={150}
							imageHeight={150}
							imageWidth={150}
							onCrop={preview => dispatch(uploadUserAvatar(preview))}
							onClose={() => null}
							src={srcState}
						/>
					) : (
						<img src={userAvatar} alt={userDetails.firstName} onClick={() => setEditMode(userDetails.authenticationType !== 'sso_account')} />
					)}
				</Grid>
				<Grid item className={classes.labels}>
					<table>
						<tbody>
							<tr>
								<td>First name:</td>
							</tr>
							<tr>
								<td>Last name:</td>
							</tr>
							<tr>
								<td>Email:</td>
							</tr>
							<tr>
								<td>Phone:</td>
							</tr>
							<tr>
								<td>Organization:</td>
							</tr>
							<tr>
								<td>Title:</td>
							</tr>
							<tr>
								<td>Manager:</td>
							</tr>
							<tr>
								<td onClick={() => setShowRoles(s => !s)}><a href="javascript:void(0)">My roles</a></td>
							</tr>
						</tbody>
					</table>
				</Grid>
				<Grid item className={classes.values}>
					<table>
						<tbody>
							<tr>
								<td>{userDetails.firstName}</td>
							</tr>
							<tr>
								<td>{userDetails.lastName}</td>
							</tr>
							<tr>
								<td>{userDetails.email}</td>
							</tr>
							<tr>
								<td>{userDetails.phone}</td>
							</tr>
							<tr>
								<td>{userDetails.organization}</td>
							</tr>
							<tr>
								<td>{userDetails.title}</td>
							</tr>
							<tr></tr>
						</tbody>
					</table>
				</Grid>
			</Grid>

			{userDetails?.roles?.length === 0 &&
				<Grid container justify="center" spacing={3}>
					<TextField
						InputProps={{ id: "idOrganization-input" }}
						name="idOrganization"
						select
						margin="normal"
						variant="outlined"
						label="Select your organization"
						onChange={handleChange}
						error={userOrganization.showError}
						helperText={userOrganization.showError ? "Please select your organization from the list." : ""}
						className={classes.selectTextField}
						InputLabelProps={{ shrink: !!userOrganization.value }}
					>
						{organizations?.map(og => (
							<MenuItem key={og.id} value={og.id}>
								{`[${og.activeDirectoryCode}] ${og.name}`}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			}
			{userDetails?.roles?.length > 0 && showRoles &&
				<DataTableContainer style={{ paddingTop: "5px", paddingBottom: "20px" }}>
					<DataTable>
						<HeaderBody>
							<HeaderRow>
								<HeaderCell>Organization</HeaderCell>
								<HeaderCell>Assigned roles</HeaderCell>
							</HeaderRow>
						</HeaderBody>
						<DataBody>
							{userDetails?.roles?.map(ur => (
								<DataRow>
									<DataCell style={{ textAlign: "left", width: "30%" }} >{ur.organization}</DataCell>
									<DataCell style={{ textAlign: "left" }} >{ur.roles.join(', ')}</DataCell>
								</DataRow>)
							)}
						</DataBody>
					</DataTable>
				</DataTableContainer>
			}
			<Grid container justify="center" spacing={3} style={{ paddingTop: "15px" }}>
				<Button variant="contained" color="primary" className={classes.btn} onClick={() => onCloseEvent()}>
					Continue
				</Button>
			</Grid>
		</div>
	);

	return (
		<>
			{modalState.isOpen && (
				<Modal open={modalState.isOpen} onClose={onCloseEvent} title={"Welcome to the myProject portal " + userDetails.firstName + " !"} body={body}></Modal>
			)}
		</>
	);
};
