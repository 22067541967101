import React from "react";
import StyledMenu from "../common/styled-menu";
import StyledMenuItem from "../common/styled-menu-item";

export default ({ onLogoutClick, onSettingsClick, ...props }) => {
	return (
		<StyledMenu
			getContentAnchorEl={null}
			style={{ marginTop: "10px" }}
			anchorEl={props.anchorElement}
			open={Boolean(props.anchorElement)}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			transformOrigin={{ vertical: "top", horizontal: "center" }}>
			<StyledMenuItem onClick={onSettingsClick}>Settings</StyledMenuItem>
			<StyledMenuItem id="log-out-button" onClick={onLogoutClick}>
				Sign out
			</StyledMenuItem>
		</StyledMenu>
	);
};
