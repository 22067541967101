import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectNotifications, selectCounter } from "./selectors";
import { useSnackbar } from "notistack";

export default props => {
	const { enqueueSnackbar } = useSnackbar();
	const notifications = useSelector(selectNotifications);
	const notificationCounter = useSelector(selectCounter);

	useEffect(() => {
		if (notifications.length > 0)
			notifications.forEach(notification => {
				enqueueSnackbar(notification.message, {
					variant: notification.type,
					autoHideDuration: 5000,
					anchorOrigin: {
						vertical: "top",
						horizontal: "right",
					},
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationCounter]);

	return <></>;
};
