import * as actionType from "./action-types";
import { createAction } from "@reduxjs/toolkit";
import { CANCELED_REQUEST_MESSAGE } from "utils/utils";

export const default_notify = messages => ({
	type: actionType.NOTIFICATION,
	notifications: messages.map(m => ({ message: m, type: "default" })),
});

export const error_notify = messages => ({
	type: actionType.NOTIFICATION,
	notifications: messages.filter(message => message !== CANCELED_REQUEST_MESSAGE).map(m => ({ message: m, type: "error" })),
});

export const success_notify = messages => ({
	type: actionType.NOTIFICATION,
	notifications: messages.map(m => ({ message: m, type: "success" })),
});

export const info_notify = messages => ({
	type: actionType.NOTIFICATION,
	notifications: messages.map(m => ({ message: m, type: "info" })),
});

export const warning_notify = messages => ({
	type: actionType.NOTIFICATION,
	notifications: messages.map(m => ({ message: m, type: "warning" })),
});

export const sidebarToggle = () => ({
	type: actionType.SIDEBAR_TOGGLE,
});

export const uploadUserAvatar = avatar => ({
	type: actionType.UPLOAD_USER_AVATAR,
	avatar,
});

export const uploadUserAvatarFetch = () => ({
	type: actionType.UPLOAD_USER_AVATAR_FETCH,
});

export const uploadUserAvatarFetched = avatar => ({
	type: actionType.UPLOAD_USER_AVATAR_FETCHED,
	avatar,
});

export const getOrganizations = () => ({
	type: actionType.GET_ORGANIZATIONS,
});

export const getOrganizationsFetch = () => ({
	type: actionType.GET_ORGANIZATIONS_FETCH,
});

export const getOrganizationsFetched = organizations => ({
	type: actionType.GET_ORGANIZATIONS_FETCHED,
	organizations,
});

export const navigateHome = () => ({
	type: actionType.NAVIGATE_HOME,
});

export const displayUserInfo = payload => ({
	type: actionType.DISPLAY_USER_INFO,
	payload,
});

export const getNotificationFlag = () => ({
	type: actionType.GET_NOTIFICATION_FLAG,
});

export const getNotificationFlagFetch = () => ({
	type: actionType.GET_NOTIFICATION_FLAG_FETCH,
});

export const getNotificationFlagFetched = flagNotifications => ({
	type: actionType.GET_NOTIFICATION_FLAG_FETCHED,
	flagNotifications,
});

export const receiveNotifications = createAction("LAYOUT/RECEIVE_NOTIFICATIONS");
export const setFormErrors = createAction("LAYOUT/SET_FORM_ERRORS");
export const getCarouselContent = createAction("LAYOUT/GET_CAROUSEL_CONTENT");
export const getCarouselContentFetch = createAction("LAYOUT/GET_CAROUSEL_CONTENT_FETCH");
export const getCarouselContentFetched = createAction("LAYOUT/GET_CAROUSEL_CONTENT_FETCHED");

export const getCurrentUserDetails = createAction("LAYOUT/GET_CURRENT_USER_DETAILS");
export const getCurrentUserDetailsFetch = createAction("LAYOUT/GET_CURRENT_USER_DETAILS_FETCH");
export const getCurrentUserDetailsFetched = createAction("LAYOUT/GET_CURRENT_USER_DETAILS_FETCHED");

export const saveUserDefaultOrganization = createAction("LAYOUT/saveUserDefaultOrganization");
export const saveUserDefaultOrganizationFetch = createAction("LAYOUT/saveUserDefaultOrganization");
export const saveUserDefaultOrganizationFetched = createAction("LAYOUT/saveUserDefaultOrganization");

export const addUserNotificationsOptions = createAction("LAYOUT/addUserNotificationsOptions");
export const updateNotificationServices = createAction("LAYOUT/updateNotificationServices");
export const updateNotificationServicesFetch = createAction("LAYOUT/updateNotificationServicesFetch");
export const updateNotificationServicesFetched = createAction("LAYOUT/updateNotificationServicesFetched");