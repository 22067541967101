import { createMuiTheme } from "@material-ui/core/styles";

const inputPadding = "13px";

const theme = createMuiTheme({
	typography: {
		fontFamily: "NotoSans-Light",
		button: {
			textTransform: "capitalize",
		},
	},
	palette: {
		primary: {
			light: "#8fadcc",
			main: "#4477aa",
			dark: "#004488",
			contrast: "#ffffff",
		},
		secondary: {
			light: "#edf0f4",
			main: "#D2DAE1",
			dark: "#99a8b7",
			contrast: "#2d3740",
		},
		error: {
			light: "#cd5257",
			main: "#c1272d",
			dark: "#91080d",
			contrast: "#ffffff",
		},
		text: {
			primary: "#2d3740",
			disabled: "#abb2b8",
		},
		yokogawaColors: {
			black: "black",
			white: "#ffffff",
			grey: "#fafafa",
			lightGray: "#edf0f4",
			green: "#8CC63F",
		},
		warning: {
			light: "#ffc166",
			main: "#ff9800",
			dark: "#f57c00",
			contrast: "#ffffff",
		},
		action: {
			active: "#0066cc",
			hover: "#4477aa",
			selected: "#004488",
			disabled: "#a5bdd6",
		},
		info: {
			light: "#5cadff",
			main: "#3399ff",
			dark: "#004d99",
			contrast: "#ffffff",
		},
		success: {
			light: "#3f9899",
			main: "#427f80",
			dark: "#0b5f60",
			contract: "#ffffff",
		},
		label: {
			ok: "#8CC63F",
			hover: "#769e36",
		},
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				"*::-webkit-scrollbar-track": {
					boxShadow: "inset 0 0 5px grey",
					borderRadius: "5px",
				},
				"*::-webkit-scrollbar": {
					width: "5px",
					height: "5px",
				},
				"*::-webkit-scrollbar-thumb": {
					background: "darkgrey",
					borderRadius: "5px",
				},
				"*::-webkit-scrollbar-thumb:hover": {
					background: "grey",
				},
			},
		},
		MuiFormControl: {
			root: {},
		},

		MuiInputBase: {
			root: {
				margin: "10px",
			},
		},

		MuiFormLabel: {
			root: {
				color: "#0066CC",
			},
			outlined: {
				color: "red",
			},
		},
		MuiSelect: {
			select: {
				// minWidth: "150px",
				// maxWidth: "220px",
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: "translate(14px, 15px) scale(1)",
			},
			root: {
				backgroundColor: "white",
				color: "#0066CC",
				padding: "0 10px 0 0",
				margin: 14,
				"&$formControl": {
					"&$shrink": {
						transform: "translate(14px, -13px) scale(0.90);",
						zIndex: 1,
						color: "#0066CC",
					},
				},
			},
		},
		MuiOutlinedInput: {
			notchedOutline: {
				borderColor: "#0066CC",
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				"&$Mui": {
					expanded: {
						minHeight: "48px",
					},
				},
			},
		},
		MuiListItem: {
			root: {
				"&.Mui-selected": {
					color: "#fff",
					"&.Mui-focusVisible": {
						background: "#4477aa",
					},
				},
				"&.Mui-focusVisible": {
					background: "none",
				},
			},
			button: {
				"&:hover": {
					color: "#fff",
				},
			},
		},
		MuiIconButton: {
			root: {
				padding: 5,
				"&:hover": {
					backgroundColor: "none",
				},
			},
		},
		MuiAutocomplete: {
			option: {
				"&:hover": {
					color: "#fff",
				},
			},
		},
	},
	props: {
		PrivateNotchedOutline: {
			style: {
				padding: inputPadding,
				//border: "solid 1px red"
			},
		},
		MuiButtonBase: {
			// The default props to change
			disableRipple: true, // No more ripple, on the whole application 💣!
		},
	},
});

export default theme;
