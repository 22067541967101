import React from "react";
import clsx from "clsx";

import { Drawer, IconButton, ListItemIcon, MenuList, MenuItem, Typography, ListItemText, Box } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { YokoIcon } from "../../utils";
import { Divider } from "@material-ui/core";

import StyledMenu from "../common/styled-menu";
import StyledMenuItem from "../common/styled-menu-item";

export default function LeftMenu({ userDetails, open, ToggleSidebar, menuItems, footerMenuItems, navigateTo, currentMenuItem, setCurrentMenuItem, isImplemented, displayHelpDesk, displayStream, displayReleaseNotes, ...props }) {
	const { classes } = props;
	const handleSubMenuClick = path => {
		navigateTo(path);
		setCurrentMenuItem(null);
	};

	const createMenuItem = (item, i) => {
		if (item.children && item.children.length > 0) {
			return (
				<MenuItem
					id={`left-menu ${item.text}`}
					key={i}
					onClick={e =>
						setCurrentMenuItem({
							target: e.currentTarget,
							children: item.children,
						})
					}>
					<ListItemIcon className={classes.menuIcon}>
						<YokoIcon type={item.icon} size={30} />
					</ListItemIcon>
					<Typography variant="button" style={{ flex: 1, whiteSpace: "nowrap", }}>
						{item.text}
						{item.isNew && <span className={classes.soonBoxItem}>NEW</span>}
					</Typography>
					<ArrowRightIcon />
				</MenuItem>
			);
		} else if (item.text === "Divider") {
			return <Divider key={i} />;
		} else {
			return (
				<MenuItem id={`left-menu ${item.text}`} key={i} onClick={() => navigateTo(item.path)}>
					<ListItemIcon className={classes.menuIcon}>
						<YokoIcon type={item.icon} size={30} />
					</ListItemIcon>
					<Typography variant="button" style={{ flex: 1, position: "relative", whiteSpace: "nowrap", }}>
						{item.text}
						{!item.isImplemented && <span className={classes.soonBoxItem}>SOON</span>}
						{item.isNew && <span className={classes.soonBoxItem}>NEW</span>}
					</Typography>
				</MenuItem>
			);
		}
	};

	return (
		<Drawer variant="permanent" classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }} open={open}>
			<div className={classes.toolbarIcon}>
				<IconButton onClick={() => ToggleSidebar()}>
					<ChevronLeftIcon className={classes.sidebarToggleIcon} style={{ transform: !open ? "rotate(180deg)" : null }} />
				</IconButton>
			</div>
			<MenuList className={classes.menuLi}>{menuItems?.map((item, i) => createMenuItem(item, i))}</MenuList>

			<MenuList >{footerMenuItems?.map((item, i) => createMenuItem(item, i))}</MenuList>

			{currentMenuItem ? (
				<StyledMenu
					getContentAnchorEl={null}
					onClose={() => setCurrentMenuItem(null)}
					anchorEl={currentMenuItem.target}
					open={Boolean(currentMenuItem.target && currentMenuItem.children.length > 0)}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "left" }}
					isStyled={true}>
					{currentMenuItem.children.map((item, i) => (
						<StyledMenuItem id={`left-menu-children ${item.text}`} key={i} onClick={() => handleSubMenuClick(item.path)}>
							<ListItemText primary={item.text} />
							<Typography>{!item.isImplemented && <span className={classes.soonBoxChildItem}>SOON</span>}</Typography>
							<Typography>{item.isNew && <span className={classes.soonBoxChildItem}>NEW</span>}</Typography>
						</StyledMenuItem>
					))}
				</StyledMenu>
			) : null}
		</Drawer>
	);
}
