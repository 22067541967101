import React from 'react';
import { Tooltip as MuiTooltip, Typography, Grid, Switch, FormGroup, Divider, Paper, FormControlLabel } from '@material-ui/core';
import { YokoIcon } from "utils";

const Tooltip = ({classes, notificationServices, handleSwitchChange, ...props }) => {
	
	const SwitchControl = React.memo(({ service, classes, onToggle }) => {
		return (
			<FormControlLabel
				key={service.name}
				label={service.type}
				className={classes.labelWrapper}
				control={
					<Switch
						checked={service.isActive}
						value={service.isActive}
						disabled={service.isDisabled}
						className={service.isActive ? classes.active : classes.inactive}
						onChange={onToggle} 
						color="primary"/>
				}
			/>
		);
	}, (prevProps, nextProps) => {
		return prevProps.service.isActive === nextProps.service.isActive;
	});

	const HTMLContent = () => {
		const groupedNotifications = notificationServices.reduce((groups, service) => {
			const group =  service.label;
			if(!groups[group]) {
				groups[group] = [];
			}
			groups[group].push(service);
			return groups;
		}, {});
		
		return (
			<Grid container className={classes.container}>
				<Typography className={classes.tooltipSettingsTitle}>Activate/Deactivate Notifications</Typography>
				<Divider className={classes.titleDivider}/>
				<FormGroup className={classes.formGroup}>
					{Object.keys(groupedNotifications).map((group) => (
						<>
							<Typography className={classes.tooltipSettingsGroups} variant="h6">{group}</Typography>
							<Paper key={group} className={classes.filtersGroup} m={1}>
								{groupedNotifications[group]?.map(service => (
									<SwitchControl
										key={service.name}
										service={service}
										classes={classes}
										onToggle={() => handleSwitchChange({ ...service, isActive: !service.isActive, notificationServices: notificationServices })}
									/>
								))}
							</Paper>
						</>
					))}
				</FormGroup>
			</Grid>
	)};

	return (
		<MuiTooltip
			arrow
			interactive
			placement="bottom"
			title={<HTMLContent/>}
			classes={{ tooltip: classes.customTooltip }} {...props}>
			<span style={{ marginRight: 10, cursor:"pointer" }}>
				<YokoIcon type="OverdueAction" size={32}/>
			</span>
		</MuiTooltip>
	);
};

export default Tooltip;
