import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		position: "absolute",
		flexGrow: 1,
		height: "calc(100% - 6px)",
		width: "calc(100% - 6px)",
		margin: 0,
		background: "rgba(255,255,255,.8)",
		borderRadius: 4,
		margin: 3,
		zIndex: 1000,
		left: 0,
		top: 0,
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: 300,
		width: 300,
	},

	"@keyframes ripple": {
		from: { transform: "scale(0)", opacity: 1 },
		to: { transform: "scale(1)", opacity: 0 },
	},

	multiRipple: {
		width: "2rem",
		height: "2rem",
		margin: "auto",
	},
	donut: {
		position: "absolute",
		width: "2rem",
		height: "2rem",
		borderRadius: "50%",
		border: "0.3rem solid",
		borderColor: theme.palette.info.main,
		animation: "1.5s $ripple infinite",
		"&:nth-child(2)": {
			animationDelay: "0.5s",
		},
	},
}));

const usePreloader = props => {
	const classes = useStyles();

	const [visible, setVisible] = useState(false);

	const preloader = visible ? (
		<Grid container className={classes.root} spacing={2} direction="row" alignItems="center" justify="center" {...props}>
			<Grid item>
				<div className={classes.multiRipple}>
					<div className={classes.donut} />
					<div className={classes.donut} />
				</div>
			</Grid>
		</Grid>
	) : null;

	return [preloader, isLoading => setVisible(isLoading)];
};

export default usePreloader;
