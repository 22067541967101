import { lazy } from "react";

import Completed from "assets/images/punch-list/Status_Completed.svg";
import Implementation from "assets/images/punch-list/Status_Implementation.svg";
import New from "assets/images/punch-list/Status_New.svg";
import Resolved from "assets/images/punch-list/Status_Resolved.svg";
import Closed from "assets/images/punch-list/Status_Closed.svg";

export const TITLE_FORMAT = {
	DEFAULT: "Default",
	CUSTOM1: "Custom1",
	CUSTOM2: "Custom2",
}

export const createComponent = (store, moduleName) => {
	return lazy(async () => {
		try {
			const moduleImported = await import(`./${moduleName}`);

			moduleImported.reducer && store.injectReducer(`${moduleName.replace(/[/-]/g, "_")}`, moduleImported.reducer);
			moduleImported.sagaWatcher && store.injectSaga(`${moduleName.replace(/[/-]/g, "_")}`, moduleImported.sagaWatcher);
			return moduleImported;
		} catch (e) {
			return console.log(e);
		}
	});
};

export const createComponentWithPathAndName = (store, path, name) => {
	return lazy(async () => {
		try {
			const moduleImported = await import(`./${path}`);

			moduleImported.reducer && store.injectReducer(`${name.replace(/[/-]/g, "_")}`, moduleImported.reducer);
			moduleImported.sagaWatcher && store.injectSaga(`${name.replace(/[/-]/g, "_")}`, moduleImported.sagaWatcher);
			return moduleImported;
		} catch (e) {
			return console.log(e);
		}
	});
};

export const createComponentWithAdditionalModules = (store, moduleName, additionalModules) => {
	return lazy(async () => {
		try {
			const moduleImported = await import(`./${moduleName}`);

			moduleImported.reducer && store.injectReducer(`${moduleName.replace(/[/-]/g, "_")}`, moduleImported.reducer);
			moduleImported.sagaWatcher && store.injectSaga(`${moduleName.replace(/[/-]/g, "_")}`, moduleImported.sagaWatcher);

			for (let index = 0; index < additionalModules.length; index++) {
				const module = additionalModules[index];
				const additionalModule = await import(`./${module}`);

				additionalModule.reducer && store.injectReducer(`${module.replace(/[/-]/g, "_")}`, additionalModule.reducer);
				additionalModule.sagaWatcher && store.injectSaga(`${module.replace(/[/-]/g, "_")}`, additionalModule.sagaWatcher);
			}

			return moduleImported;
		} catch (e) {
			return console.log(e);
		}
	});
};

export const buildCustomPunchTitle = (project, punch) => {
	switch (project?.punchNumberingFormat) {
		case TITLE_FORMAT.CUSTOM2:
			return `${project.number}_${punch?.tag}_${punch.techScopeInfo}_ID${punch.number}`.toUpperCase();
		case TITLE_FORMAT.CUSTOM1:
			return `${project.number}_${punch?.tag}_${punch.techScopeInfo}_ID${punch.systemId}`.toUpperCase();
		default:
			return `ID ${punch.systemId}`;
	}
}

export const getStatusIcon = status => {
	switch (status) {
		case "New":
			return New;
		case "Implementation":
			return Implementation;
		case "Resolved":
			return Resolved;
		case "Closed":
			return Closed;
		default:
			return Completed;
	}
};

export const getTqStatusIcon = status => {
	switch (status) {
		case "New":
			return New;
		case "Open":
			return Completed;
		case "Closed":
			return Closed;
		default:
			return Completed;
	}	
}