import React from "react";
import { TableHead } from "@material-ui/core";

export const HeaderBody = ({ children, classes, ...props }) => {
	return (
		<>
			<TableHead {...props}>{children}</TableHead>
		</>
	);
};
export default HeaderBody;
