import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { createSagaInjector } from "./saga-injector";
import { createReducerInjector } from "./reducer-injector";
import rootReducer from "containers/layout/layout-reducer";
import { layoutSagaWatcher } from "containers/layout/saga";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const browserRouter = connectRouter(history);
const rootReducers = { router: browserRouter, rootReducer };

const combinedRootReducers = combineReducers(rootReducers);

let enhancer;

if (process.env.NODE_ENV === "development") {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	//enhancer = composeEnhancers(applyMiddleware(signalRMiddleware, sagaMiddleware, logger, routerMiddleware(history)));
	enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, logger, routerMiddleware(history)));
} else {
	enhancer = compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)));
}

function configureStore() {
	const store = createStore(combinedRootReducers, enhancer);
	let reducerInjector = createReducerInjector(store, rootReducers);

	store.injectReducer = reducerInjector.add;
	store.injectSaga = createSagaInjector(sagaMiddleware.run, [{ key: "layoutSaga", value: layoutSagaWatcher }]);
	store.removeReducer = reducerInjector.remove;

	return store;
}

export const store = configureStore();
