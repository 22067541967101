import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/topbar";
import * as selector from "./selectors";
import * as action from "./actions";
import { push } from "connected-react-router";

export default ({ ...props }) => {
	const selectedFlagNotifications = useSelector(selector.selectFlagNotifications);
	const currentProject = useSelector(selector.selectProject);
	const userAvatar = useSelector(selector.getUserAvatar);
	const userNotificationsOptions = useSelector(selector.getUserDetails);
	const notificationServices = useSelector(selector.selectNotificationServices);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(action.getNotificationFlag());
	}, []);

	useEffect(() => {
		dispatch(action.addUserNotificationsOptions(userNotificationsOptions.notifications));
	}, [userNotificationsOptions.notifications]);

	const handleSwitchChange = useCallback(service => {
		const payload = service.notificationServices.reduce((acc, notification) => {
			acc[notification.name] = notification.name === service.name ? service.isActive : notification.isActive;
			return acc;
		}, {});
			
		dispatch(action.updateNotificationServices({
			notifications: { ...payload }
		}));
	});

	return (
		<TopBar
			userAvatar={userAvatar}
			currentProject={currentProject}
			notifications={selectedFlagNotifications}
			onLogoClick={() => dispatch(push("/my-tasks"))}
			onLogoutClick={() => dispatch(push("/logout"))}
			onSettingsClick={() => {
				dispatch(action.displayUserInfo(false));
				setTimeout(() => {
					dispatch(action.displayUserInfo(true));
				}, 1);
			}}
			notificationServices = {notificationServices}
			handleSwitchChange = {handleSwitchChange}
		/>
	);
};
