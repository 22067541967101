import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

export const setPageDefaultStyle = theme => ({
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: `calc(100vh - ${theme.mixins.toolbar})`,
		overflow: "auto",
		padding: theme.spacing(4),
	},

	alert: {
		backgroundColor: "#fff3cd",
		borderRadius: "4px",
		padding: "10px",
	},
});

const createStyle = scopedStyle => makeStyles(theme => _.merge(setPageDefaultStyle(theme), scopedStyle(theme)));
export default createStyle;
