import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import CacheBuster from "react-cache-buster";
import { buildVersion } from "../package.json";

import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider, useSnackbar } from "notistack";
import yokoTheme from "./yokogawa-theme";

import Layout from "./containers/layout";
import { store, history } from "./store";
import createRoutes from "./containers/routes";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";

import { environmentProvider } from "./environment-provider";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

let routes = createRoutes(store);

function SnackbarCloseButton({ key }) {
	const { closeSnackbar } = useSnackbar();
	return (
		<IconButton onClick={() => closeSnackbar(key)} color="inherit">
			<CloseIcon fontSize="small" />
		</IconButton>
	);
}

function App() {
	const env = environmentProvider();

	const useStyles = makeStyles(theme => ({
		envMarker: {
			background: `url(${env.image})`,
			backgroundRepeat: "no-repeat",
			position: "relative",
			display: "flex",
			height: "25px",
			width: "100%",
			zIndex: 9999999,
			marginBottom: "-25px",
			paddingBottom: "25px",
			backgroundPosition: "center",
		},
	}));

	if (!("Notification" in window)) {
		alert("Please note that this browser does not support system notifications!");
	} else {
		Notification.requestPermission();
	}

	const classes = useStyles();

	return (
		<CacheBuster
			currentVersion={buildVersion}
			isEnabled={true} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={<p>Loading ...</p>} //If not pass, nothing appears at the time of new version check.
		>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<ThemeProvider theme={yokoTheme}>
						<CssBaseline />
						<SnackbarProvider maxSnack={3} action={key => <SnackbarCloseButton key={key} />}>
							{env.show && <div className={classes.envMarker}>&nbsp;</div>}
							<Layout routes={routes} />
						</SnackbarProvider>
					</ThemeProvider>
				</ConnectedRouter>
			</Provider>
		</CacheBuster>
	);
}

export default App;
