import { combineReducers } from "redux";

export function createReducerInjector(store, rootReducers) {
	const injectedReducers = [];
	const isInjected = key => injectedReducers.some(ir => ir === key);

	store.asyncReducers = {};

	return {
		add: (key, reducer) => {
			if (!isInjected(key)) {
				store.asyncReducers[key] = reducer;
				store.replaceReducer(createReducer(store.asyncReducers, rootReducers));
				injectedReducers.push(key);
			}
		},
		remove: key => {
			if (!isInjected(key)) {
				return;
			}
			delete store.asyncReducers[key];
			delete injectedReducers[key];
			store.replaceReducer(createReducer(store.asyncReducers, rootReducers));
		},
	};
}

function createReducer(asyncReducers, rootReducers) {
	return combineReducers({
		...asyncReducers,
		...rootReducers,
	});
}
