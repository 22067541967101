import axios from "axios";
import axiosRetry from "axios-retry";

import { handleError } from "./handlers";

let axiosInstances = {};

export function cancelPreviousPageRequests(previousPath) {
	if (axiosInstances[previousPath]?.controller) {
		axiosInstances[previousPath].controller.abort();
	}
}

export const getInstance = pathname => {
	if (axiosInstances[pathname] && !axiosInstances[pathname]?.controller?.signal?.aborted) {
		return axiosInstances[pathname].value;
	} else {
		const customAxiosInstance = axiosInstance();
		axiosInstances[pathname] = customAxiosInstance;

		return axiosInstances[pathname].value;
	}
};

const axiosInstance = () => {
	const controller = new AbortController();

	let axiosInstance = {
		controller,
		value: axios.create({
			signal: controller.signal,
		}),
	};

	axiosInstance.value.interceptors.response.use(
		response => {
			return Promise.resolve(response);
		},
		error => handleError(error)
	);

	axiosRetry(axiosInstance.value, {
		retries: 3,
		retryDelay: retryCount => retryCount * 2000,
		retryCondition: error => {
			return (
				(error.config.method == "GET" || error.config.method == "OPTIONS" || error.config.method == "DELETE" || error.config.method == "PUT") &&
				error.status >= 500
			);
		},
	});

	return axiosInstance;
};

export default axiosInstance;
export const cancelTokenSource = () => axios.CancelToken.source();
