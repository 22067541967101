import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as actionType from "./action-types";
import UserService from "../../services/user-service";
import OrganizationService from "../../services/organization-service";
import UiConfigurationService from "../../services/ui-configuration-service";
import * as actions from "./actions";
import { push } from "connected-react-router";
import hash from "object-hash";
import { setCarouselInfo, getCarouselInfo } from "../../services/api-service/local-storage";

function* setUserAvatar(action) {
	try {
		yield put(actions.uploadUserAvatarFetch());
		yield call(UserService.uploadUserAvatar, action.avatar);
		yield put(actions.uploadUserAvatarFetched(action.avatar));
	} catch (error) {
		yield put(actions.error_notify(error));
	}
}

function* getUserDetails() {
	try {
		yield put(actions.getCurrentUserDetailsFetch());
		let { data: user } = yield call(UserService.getCurrentUserDetails);
		let { data: permissions } = yield call(UserService.getUserDataPermissions, user.uid);
		yield put(
			actions.getCurrentUserDetailsFetched({
				...user,
				organizationReadPermisions: [...new Set(permissions.dataReadPermissions.map(drp => drp.organization))],
				writePermissions: permissions.dataWritePermissions,
				readPermissions: permissions.dataReadPermissions
			})
		);
	} catch (error) {
		yield put(actions.error_notify(Array.isArray(error) ? error : [error]));
	}
}

function* getOrganizations() {
	try {
		yield put(actions.getOrganizationsFetch());
		let { data } = yield call(OrganizationService.getOrganizations);
		yield put(actions.getOrganizationsFetched(data));
	} catch (error) {
		yield put(actions.error_notify(error));
	}
}

function* navigateHome() {
	try {
		yield put({ type: "GET_PROJECT_FETCHED", project: null });
		yield put(push("/"));
	} catch (error) {
		yield put(actions.error_notify(error));
	}
}

function* getNotificationFlag() {
	try {
		yield put(actions.getNotificationFlagFetch());
		//let { data } = yield call();
		const data = [
			{
				uid: "3t3yy3y3y3y3y3y3",
				title: "E20202020 - Cold Drinks",
				text: "Project Action item has been asigned to you. Click to view your action items.",
			},

			{
				uid: "3t3yy3y3y3y3y321",
				title: "E20202020 - Hot Coffee",
				text: "A phase gate review for Phase 1 - Project management has been created and needs your digital signature. Click here to navigate to Phase gate review list overview.",
			},
			{
				uid: "3t3yy3y3y3y3y621",
				title: "E20202020 - Hot Chocolate",
				text: "A phase gate review for Phase 1 - Project management has been created and needs your digital signature. Click here to navigate to Phase gate review list overview.",
			},
		];
		yield put(actions.getNotificationFlagFetched(data));
	} catch (error) {
		yield put(actions.error_notify(error));
	}
}

function* getCarouselContent() {
	try {
		yield put(actions.getCarouselContentFetch());
		let { data } = yield call(UiConfigurationService.getCarouselImages);
		const carouselHash = hash(data);
		const localCarousel = getCarouselInfo();

		if (carouselHash !== localCarousel.hash) setCarouselInfo({ show: true, hash: carouselHash });

		yield put(actions.getCarouselContentFetched(data));
	} catch (error) {
		yield put(actions.error_notify(Array.isArray(error) ? error : [error]));
	}
}

function* saveUserDefaultOrganization(action) {
	try {
		yield call(UserService.saveUserDefaultOrganization, action.payload);
		yield put(actions.getCurrentUserDetails())
	} catch (error) {

	}
}

function* updateNotificationServices(action) {
	try {
		yield put(actions.updateNotificationServicesFetch());
		yield call(UserService.updateNotificationServices, action.payload);
		yield put(actions.updateNotificationServicesFetched(action.payload));
	} catch (error) {
		yield put(actions.updateNotificationServicesFetched([]));
	}
}

export function* layoutSagaWatcher() {
	yield takeLatest(actions.getCurrentUserDetails, getUserDetails);
	yield takeLatest(actionType.UPLOAD_USER_AVATAR, setUserAvatar);
	yield takeLatest(actionType.GET_ORGANIZATIONS, getOrganizations);

	yield takeLatest(actionType.NAVIGATE_HOME, navigateHome);
	yield takeLatest(actionType.GET_NOTIFICATION_FLAG, getNotificationFlag);
	yield takeLatest(actions.getCarouselContent.toString(), getCarouselContent);
	yield takeLatest(actions.updateNotificationServices.toString(), updateNotificationServices);

	yield takeLatest(actions.saveUserDefaultOrganization.toString(), saveUserDefaultOrganization);
}
