import { deepPurple } from "@material-ui/core/colors";

export default theme => ({
	purple: {
		backgroundColor: deepPurple[500],
	},
	avatar: {},
	labels: {
		fontWeight: "600",
	},
	values: {},
	btn: {
		background: theme.palette.action.active,
		"&:hover": {
			background: theme.palette.action.hover,
		},
		"&:active": {
			background: theme.palette.action.selected,
		},
		"& .MuiButton-label": {
			background: "none",
		},
	},
	selectTextField: {
		width: 350,
		"& .MuiSelect-select": {
			maxWidth: '100%',
			textAlign: 'left'
		}
	},
});
