export default theme => ({
	headerCell: {
		backgroundColor: theme.palette.primary.main,
		align: "center",
		color: theme.palette.primary.contrast,
		textAlign: "center",
		align: "center",
		backgroundColor: theme.palette.primary.main,
		lineHeight: "1",
		padding: theme.spacing(1),
		textAlign: "center",
	},
});
