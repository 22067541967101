import createStyle from "../../styles";

const setNewsBulletinStyle = theme => ({
	image: {
		maxHeight: "575px",
	},
	txtStyle: {
		fontSize: "0.875rem",
		fontFamily: "NotoSans-Regular",
		color: "black",
	},
});

export default createStyle(setNewsBulletinStyle);
