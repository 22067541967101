import { max, over } from "lodash";

const styles = theme => ({
	customTooltip: {
		backgroundColor: theme.palette.secondary.light,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(1),
		border: `solid 1px ${theme.palette.secondary.main}`,
		maxWidth: 320,
	},
	arrow: {
		backgroundColor: theme.palette.secondary.light,
		color: theme.palette.secondary.light,
	},
	labelWrapper: {
		width: "100%",
		padding: "5px 10px",
		borderBottom: `solid 1px ${theme.palette.secondary.main}`,
		overflow: "hidden",
		margin: 0,
		"& .MuiFormControlLabel-label": {
		},
	},
	tooltipSettingsTitle: {
		color: theme.palette.action.active,
		fontFamily: "NotoSans-SemiBold",
		fontSize: "18px",
		paddingBottom: "1vh",
		display: "flex",
	},
	tooltipSettingsGroups: {
		color: theme.palette.action.active,
		fontFamily: "NotoSans-SemiBold",
		fontSize: "1rem",
		paddingBottom: 5,
		display: "flex",
		backgroundColor: theme.palette.secondary.light,
		marginTop:10
	},
	formGroup: {
		width: "100%",
	},
	filtersGroup: {
		"& > :first-child": {
		}
	},
	tooltipSettingsOptions: {
		color: theme.palette.text.primary,
		fontSize: "0.8rem",
		fontFamily: "NotoSans-Regular",
	},
	active: {
		"& .MuiSwitch-thumb": {
			backgroundColor: theme.palette.action.active,
		}
	},
	inactive: {
	},
	titleDivider:{
		width: "100%",
		borderTop: "solid 1px #c5c5c5",
		margin: 0,
		borderBottom: "solid 1px white"
	}
});

export default styles;
