export default theme => ({
	rowStyle: {
		transition: "all 200ms cubic-bezier(0.250, 0.250, 0.750, 0.750)",
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.yokogawaColors.grey,
		},
		"&:hover": {
			backgroundColor: theme.palette.secondary.main,
		},
		userSelect: "none",
	},
});
