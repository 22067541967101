import React from "react";
import { TableCell } from "@material-ui/core";

export default ({ children, width, className, classes, ...props }) => {
	return (
		<>
			<TableCell style={{ width: width }} className={classes.headerCell} {...props}>
				{children}
			</TableCell>
		</>
	);
};
