import React from "react";
import { TableRow } from "@material-ui/core";

export default ({ children, classes, ...props }) => {
	return (
		<>
			<TableRow {...props}>{children}</TableRow>
		</>
	);
};
