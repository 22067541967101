import http from "./api-service/base-service";
import { USER_PROFILE_SERVICE_URI } from "./api-service/constants";

function getOrganizations() {
	return http.get(`${USER_PROFILE_SERVICE_URI}/organizations`);
}

function getOrganizationsById(id) {
	return http.get(`${USER_PROFILE_SERVICE_URI}?$Filter=id eq ${id}`);
}

const OrganizationService = {
	getOrganizations,
	getOrganizationsById,
};

export default OrganizationService;
