import React from "react";
import { TableRow } from "@material-ui/core";

export default ({ children, classes, onClick, ...props }) => {
	return (
		<>
			<TableRow className={classes.rowStyle} {...props} onClick={onClick}>
				{children}
			</TableRow>
		</>
	);
};
