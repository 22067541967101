export default theme => ({
	modal: {
		position: "absolute",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "scroll",
	},

	modalTitle: {
		fontFamily: "NotoSans-Light",
		fontSize: 24,
		color: theme.palette.action.active,
		marginTop: 0,
	},

	paper: {
		backgroundColor: theme.palette.background.paper,
		outline: "none",
		width: "800px",
		borderRadius: "4px",
		boxShadow: "7px 7px 7px -1px rgba(112,112,112,0.75)",
		padding: theme.spacing(4),
		position: "relative",
		overflow: "hidden",
	},
	textWidth: {},
	inputDisabled: {},
	sendBtn: {},
	btnStyle: {},
	textBoxStyle: {},
	info: {},
	btnMaxWidth: {},
	textField: {},
	alert: {},
	activeColor: {},
	appBarSpacer: {},
	content: {},
});
