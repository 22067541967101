export default theme => ({
	button: {
		"&:hover": {
			background: theme.palette.action.hover,
			color: theme.palette.yokogawaColors.white,
		},
		"&:active": {
			background: theme.palette.action.selected,
			color: theme.palette.yokogawaColors.white,
		},
	},
});
