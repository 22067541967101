import { Badge, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { YokoIcon } from "../../utils";
import PopOverFlagNotification from "../notification-flag-pop-over";

export default ({ notifications, ...props }) => {
	const { classes } = props;
	const [notificationPopUp, setNotificationPopUp] = useState(null);
	const [selectedItems, setSelectedItemsState] = useState({});
	const numberNotifications = notifications?.length;

	const [viewed, setViewed] = useState([]);

	const open = Boolean(notificationPopUp);

	const openNotificationPopUp = e => {
		setNotificationPopUp(e.currentTarget);
	};

	const clickNotificationState = itemUid => {
		setSelectedItemsState({
			...selectedItems,
			[itemUid]: true,
		});
	};
	const [expandRead, setExpandRead] = useState(false);
	const onClickRead = () => {
		setExpandRead(!expandRead);
	};
	const setViewedCountedItems = itemUid => {
		if (viewed.indexOf(itemUid) === -1) {
			setViewed([...viewed, itemUid]);
		}
	};

	return (
		<>
			{notifications ? (
				<div className={classes.content}>
					<IconButton color="inherit" className={classes.flagButton} onClick={openNotificationPopUp}>
						<Badge badgeContent={numberNotifications - viewed.length} color="error">
							<YokoIcon type="icon_notification_flag" customStyle={{ color: "white" }} size={30} />
						</Badge>
					</IconButton>
					<PopOverFlagNotification
						open={open}
						notifications={notifications}
						notificationPopUp={notificationPopUp}
						setNotificationPopUp={setNotificationPopUp}
						count={numberNotifications - viewed.length}
						setViewed={setViewedCountedItems}
						clickNotificationState={clickNotificationState}
						selectedItems={selectedItems}
						setSelectedItemsState={setSelectedItemsState}
						expandRead={expandRead}
						onClickRead={onClickRead}
					/>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
